import { atob } from "./funcoes";

export const TOKEN_KEY = "@Contador-Token";
export const NOMEUSUARIO_KEY = "@Contador-NomeUsuario";
export const IDUSUARIO_KEY = "@Contador-IdUsuario";
export const EMAILUSUARIO_KEY = "@Contador-EmailUsuario";
export const linkApi = process.env.REACT_APP_PUBLIC_URL || (window.location.href.indexOf('localhost') > -1 ? 'https://localhost:7205/' : window.location.href.indexOf('hom') > -1 ? 'http://apihom.gestorweb.com.br/' : 'https://api.gestorweb.com.br/');

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getNomeUsuario = () => localStorage.getItem(NOMEUSUARIO_KEY);
export const getIdUsuario = () => localStorage.getItem(IDUSUARIO_KEY);
export const getEmailUsuario = () => localStorage.getItem(EMAILUSUARIO_KEY);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const setNomeUsuario = nomeUsuario => {
  localStorage.setItem(NOMEUSUARIO_KEY, nomeUsuario);
};
export const setIdUsuario = idUsuario => {
  localStorage.setItem(IDUSUARIO_KEY, idUsuario);
};
export const setEmailUsuario = emailUsuario => {
  localStorage.setItem(EMAILUSUARIO_KEY, emailUsuario);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(NOMEUSUARIO_KEY);
  localStorage.removeItem(IDUSUARIO_KEY);
  localStorage.removeItem(EMAILUSUARIO_KEY);
  if (window.location.pathname !== '/') window.location = '/';
};

export const getUserInfo = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
