import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, notification } from 'antd';
import { MailOutlined, ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import api from '../../services/api';

export default function EsqueciSenha({ onAcessar, formulario }) {

    function enviarEmailRecuperarSenha() {
        let emailUsuario = formulario.getFieldValue().email;
        if (!!emailUsuario) {
            api.post(`Contador/EsqueciMinhaSenha/${emailUsuario}`).then(
                res => {
                    notification.success({ message: 'Operação concluída', description: "Enviamos um E-mail para você com as instruções para recuperar sua senha!" });
                    onAcessar();
                }
            ).catch(
                error => {
                    notification.warning({ message: `AVISO!`, description: `Usuário não encontrado!` });
                }
            )
        }
    }

    return (
        <Form form={formulario} name="login" size="middle">
            <Row gutter={[0, 16]} className="m-t-16">
                <Col span={24}>
                    Informe abaixo o e-mail utilizado para acessar o sistema e clique em [RECUPERAR SENHA]. Em instantes você irá receber em seu e-mail instruções para alterar sua senha.
                </Col>
                <Col span={24}>
                    <Form.Item name="email" rules={[{ required: true, message: 'Informe seu e-mail para recuperar sua senha.' }]}>
                        <Input placeholder="Informe seu e-mail para recuperar sua senha" prefix={<MailOutlined />} />
                    </Form.Item>
                </Col>
            </Row>
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="m-t-8">
                <Col xxl={5} xl={6} lg={24} md={5} sm={6} xs={24}>
                    <Form.Item>
                        <Button htmlType="button" onClick={() => onAcessar()} icon={<ArrowLeftOutlined />} block>
                            Acessar
                        </Button>
                    </Form.Item>
                </Col>
                <Col xxl={7} xl={9} lg={24} md={7} sm={7} xs={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size="large" block onClick={() => enviarEmailRecuperarSenha()}>
                            Recuperar Senha <SendOutlined />
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}