import React from 'react';
import { Carousel } from 'antd';

export default function SlideLogin() {
    return (
        <>
            <Carousel effect="fade" dots={false} autoplay>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(${require("../../assets/img-home.jpg")})`}}></div>
                </div>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(${require("../../assets/img-home-01.jpg")})`}}></div>
                </div>
            </Carousel>
        </>
    );
}