import { Buffer } from 'buffer';

export const btoa = (data) => {
    return Buffer.from(data);
};

export const atob = (data) => {
    return Buffer.from(data, 'base64').toString('utf-8');
};

export default function maskFormat(data, text, cpfCnpj = false) {
    if (!data) {
        return;
    }

    if (cpfCnpj) {
        if (data.toString().length > 11) {
            text = '00.000.000/0000-00';
        } else {
            text = '000.000.000-00';
        }

    }

    let onlyNumbers = data.replace(/[^0-9]/g, '');
    if (onlyNumbers === '') {
        return;
    } else {
        let result = '';
        let format = text.split('');
        let numbers = onlyNumbers.split('');
        let item = '';
        let index = 0;
        for (var i in numbers) {
            let num = numbers[i];
            if (!!format[index]) {
                let caracter = format[index];
                while (caracter !== '0') {
                    item = item + caracter;
                    index++;
                    caracter = format[index];
                }
                item = item + num;
                index++;
            } else {
                return;
            }
            result = item.replace('*', ' ');
            if (format.length === result.length) {
                return result;
            }
        }
    }
};
export const encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
}

export const decodeBase64 = (data, type = 'ascii') => {
    return Buffer.from(data, 'base64').toString(type);;
}
export const parseToken = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(decodeBase64(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};