import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Button, notification } from 'antd';
import { UnlockFilled, SendOutlined } from '@ant-design/icons';

import api from '../../services/api';
import SlideLogin from '../login/slide';
import { atob ,encodeBase64} from '../../services/funcoes';

export default function RecuperarSenha() {
    const [dadosUsuario, setDadosUsuario] = useState('');
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        let dados = window.location.href.split('hash=');
        if (dados.length > 0) {
            dados = JSON.parse(atob(dados[1]));
            setDadosUsuario(dados);
        } else {
            notification.error({ description: 'Parâmetros inválidos para realizar a alteração de uma senha.', message: 'Aviso!' });
            voltarLogin();
        }
    }, []);

    function alterarSenha() {
        if (form.getFieldValue().usu_senha === form.getFieldValue().usu_confirmaSenha) {
            let dados = {
                con_id: dadosUsuario.id,
                hash: dadosUsuario.hash,
                novaSenha: encodeBase64(form.getFieldValue().usu_senha)
            };
            setCarregando(true);
            api.post(`Contador/AlterarSenha`, dados).then(
                res => {
                    notification.success({ description: 'Sua Senha Foi Alterada Com Sucesso!', message: 'Aviso' });
                    voltarLogin();
                }
            ).catch(
                erro => {
                    notification.error({ description: 'Contador não localizado em nossa base de dados!', message: 'Aviso' });
                }
            ).finally(() => {
                setCarregando(false);
            });
        } else {
            notification.warn({ description: 'As senhas informadas não conferem, favor verificar!', message: 'Aviso' })
        }
    };

    function voltarLogin() {
        setTimeout(() => { window.location = '/#/' }, 2000);
    };

    return (
        <Layout className="vh100">
            <Layout.Content>
                <Row justify="center" className="vh100">
                    <Col span={13} className="vh100 fixed-left">
                        <SlideLogin />
                    </Col>
                    <Col span={11} className="vh100 bg-log fixed-right">
                        <Form layout="vertical" name="recuperar-senha" form={form} initialValues={{ remember: true }} size="middle" onFinish={alterarSenha}>
                            <Row align="middle" justify="center" className="vh100" gutter={[16, 16]}>
                                <Col span={18}>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <img src={require("../../assets/logoGestor.png")} width="250" alt="Gestor Web" />
                                        </Col>
                                    </Row>
                                    <Row align="middle" className="m-t-16">
                                        <Col span={24}>
                                            <Form.Item label="Nova Senha" name="usu_senha" rules={[{ required: true, message: 'Informe a Nova Senha.', }]}>
                                                <Input.Password placeholder="Senha de Acesso" prefix={<UnlockFilled />} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Confirmar Nova Senha" name="usu_confirmaSenha" dependencies={['password']}
                                                rules={[{ required: true, message: 'Confirme a Nova Senha.' }, ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('usu_senha') === value) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(new Error('As senhas informadas não conferem!'));
                                                    },
                                                }),]}>
                                                <Input.Password placeholder="Confirme a Nova Senha do Usuário" prefix={<UnlockFilled />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row align="middle" justify="end" className="m-t-16">
                                        <Col>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" loading={carregando}>
                                                    Alterar Senha <SendOutlined />
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}