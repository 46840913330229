import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Form, Input, Checkbox, Button, notification } from 'antd';
import { UnlockFilled, MailOutlined, SendOutlined } from '@ant-design/icons';

import "./index.less";
import { SlideLogin, EsqueciSenha } from "./pages";
import api from '../../services/api';
import { parseToken ,encodeBase64} from '../../services/funcoes';
import { login, setNomeUsuario, setIdUsuario, setEmailUsuario, } from '../../services/auth';

const FORMULARIO = {
    LOGIN: 1,
    ESQUECI_SENHA: 2,
}

export default function Login() {

    const [formularioExibir, setFormularioExibir] = useState(FORMULARIO.LOGIN);
    const [carregando, setCarregando] = useState(false);
    const [formulario] = Form.useForm();
 
    const onFinish = values => {
        setCarregando(true);
        values.senha = encodeBase64(values.senha);
        delete values.remember;
        api.post('Contador/Login', values).then(res => {
            if (res.status === 200) {
                const token = res.data;
                let dadosUsuario = parseToken(token);
                setNomeUsuario(dadosUsuario.name);
                setIdUsuario(dadosUsuario.UsuarioID);
                setEmailUsuario(dadosUsuario.email);
                login(token);
                setFormularioExibir(FORMULARIO.SELECIONA_EMPRESA);
                window.location = "/Home";
            }
        }, err => {
            if (!!err) {
                if (!!err.response && !!err.response.data) {
                    notification.error({ message: err.response.data || 'Falha ao efetuar o login' });
                } else {
                    notification.error({ message: 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
                }
            } else {
                notification.error({ message: 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
            }
        }).finally(() => setCarregando(false));
    };

    useEffect(() => {
        localStorage.removeItem("@GestorWeb-Token");
    }, []);
    
    return (
        <Layout className="vh100">
            <Layout.Content>
                <Row justify="center" className="vh100">
                    <Col className="vh100 fixed-left">
                        <SlideLogin />
                    </Col>
                    <Col className="vh100 fixed-right">
                    <Row align="middle" justify="center" className="vh100" gutter={[16, 16]}>
                            <Col span={18}>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <img src={require("../../assets/logoGestor.png")} width="280" alt="Gestor Web para Contador" />
                                    </Col>
                                </Row>
                                {formularioExibir === FORMULARIO.LOGIN &&
                                    <Form form={formulario} layout="vertical" name="login" initialValues={{ remember: true }} size="middle" onFinish={onFinish}>
                                        <Row gutter={[0, 8]} className="m-t-16">
                                            <Col span={24}>
                                                Identifique abaixo suas credenciais para realizar o acesso.
                                            </Col>
                                            <Col span={24} className="m-t-5">
                                                <Form.Item name="email" rules={[{ required: true, message: 'Informe seu e-mail para realizar o login.' }]}>
                                                    <Input placeholder="Informe seu e-mail" prefix={<MailOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="senha" rules={[{ required: true, message: 'Informe sua senha de acesso.' }]}>
                                                    <Input.Password placeholder="Informe sua senha" prefix={<UnlockFilled />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                                    <Col>
                                                        <Form.Item name="remember" valuePropName="checked">
                                                            <Checkbox>Salvar meus dados de login</Checkbox>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xxl={5} xl={6} lg={24} md={5} sm={6} xs={24}>
                                                        <Form.Item>
                                                            <Button type="primary" htmlType="submit" loading={carregando} size="large" block>
                                                                Acessar <SendOutlined />
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Row align="middle" justify="center">
                                                    <Col xxl={6} xl={8} lg={12} md={8} sm={8} xs={24}>
                                                        <Button onClick={() => setFormularioExibir(FORMULARIO.ESQUECI_SENHA)} size="small" block>
                                                            Esqueci minha senha <UnlockFilled />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                }
                                {
                                    formularioExibir === FORMULARIO.ESQUECI_SENHA && 
                                    <EsqueciSenha formulario={formulario} onAcessar={() => setFormularioExibir(FORMULARIO.LOGIN)} />
                                }
                            </Col>
                        </Row>
                    </Col>    
                </Row>         
            </Layout.Content>
        </Layout>
    );
}