const drawerActions = {
    CHANGE: 'changeDrawer'
}

const manutencaoActions = {
    CHANGE: 'changeManutencao'
}

const listagemActions = {
    CHANGE: 'changeListagem'
}

const parametrosEmpresaActions = {
    CHANGE: 'changeParametrosEmpresa'
}

export { 
    drawerActions, manutencaoActions, listagemActions, parametrosEmpresaActions
}