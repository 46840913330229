import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import ptBR from 'antd/lib/locale/pt_BR';
import { ConfigProvider } from 'antd';
import 'moment/locale/pt-br';
import moment from 'moment';

import "./App.less";
import { Login, Home , RecuperarSenha, AtivarConta} from "./pages";
import { StateProvider } from './state';
import { reducer } from './state/reducer';
import { isAuthenticated } from './services/auth';
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
            <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

function App() {
    moment.locale('pt-br');
    const initialState = {
        userConfig: { size: 'small' }
    };

    const FormSizeDemo = () => {
        return (
            <ConfigProvider locale={ptBR} componentSize="small">             
                <Router>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <PrivateRoute path="/home" component={Home} />
                        <Route exact path="/ativar-conta" component={AtivarConta} />
                        <Route exact path="/recuperar-senha" component={RecuperarSenha} />
                    </Switch>
                </Router>
            </ConfigProvider>
        )
    };

    return (
        <StateProvider initialState={initialState} reducer={reducer}>
            <FormSizeDemo />
        </StateProvider>
    );

}

export default App;