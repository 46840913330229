import { drawerActions, manutencaoActions, listagemActions, parametrosEmpresaActions } from '../actions'

export const reducer = (state, action) => {
    switch (action.type) {
        case drawerActions.CHANGE:
            return {
                ...state,
                ui: action.data
            };

        case manutencaoActions.CHANGE:
            return {
                ...state,
                manutencao: action.data
            };

        case listagemActions.CHANGE:
            return {
                ...state,
                listagem: { ...state.listagem, ...action.data }
            };

        case parametrosEmpresaActions.CHANGE:
            return {
                ...state,
                parametrosEmpresa: { ...state.parametrosEmpresa, ...action.data }
            };

        default:
            return state;
    }
};