import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Layout, Row, Col, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import "./index.less"
import { getEmailUsuario, getNomeUsuario, logout } from '../../services/auth';

export default function HeaderPage() {
    return (
        <Layout.Header>
            <Row align="middle">
                <Col xl={14} lg={13} md={12} sm={10} xs={10}>
                    <Link to="/">
                        <img src={require("../../assets/logoGestorWhite.png")} width="145" alt="Gestor Web" />
                    </Link>
                </Col>
                <Col xl={10} lg={11} md={12} sm={14} xs={14}>
                    <Row align="middle" justify="end">
                        <Col xl={22} lg={20} md={20} sm={18} xs={18}>
                            <Row align="middle" justify="end" gutter={[8, 0]}>
                                <Col align="right" className="txt-overflow"> 
                                    {getNomeUsuario()} <br />
                                    {getEmailUsuario()}
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={2} lg={4} md={4} sm={6} xs={6}>
                            <Button type="text" block className="text-right" onClick={() => logout()}>
                                <LogoutOutlined /> Sair
                            </Button>
                        </Col>
                    </Row>
                </Col>   
            </Row>
        </Layout.Header>
    );
}