import React from 'react';
import { Route, Switch, HashRouter } from "react-router-dom";
import { Layout } from 'antd';

import { HeaderPage } from "../../components";
import { Login, Inicial } from "../";

export default function Home() {
    return (
        <HashRouter>
            <div className="App">
                <Layout className="vh100">
                    <HeaderPage />
                    <Layout className="vh100 m-t-55">
                        <Layout className="site-layout">
                            <Layout.Content>
                                <Switch>
                                    <Route exact path="/login" component={Login} />
                                    <Route exact path="/" component={Inicial} />
                                </Switch>
                            </Layout.Content>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        </HashRouter>
    );
}