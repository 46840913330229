import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Card, Button, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { ModalDownloadXml } from "../../components/modals/";
import api from '../../services/api';
import moment from 'moment';
import { linkApi } from '../../services/auth';

export default function Inicial() {

    const [openModalModalDownloadXml, setOpenModalModalDownloadXml] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [clientesFiltro, setClientesFiltro] = useState([]);
    const [clienteSelecionado, setClienteSelecionado] = useState({});

    useEffect(() => {
        api.get(`Contador/RetornaClientes`).then(
            res => {
                setClientes(res.data);
                setClientesFiltro(res.data);
            }
        ).catch(
            erro => {
                notification.error({ description: 'Não foi possível carregar os dados!', message: 'Aviso!' });
            }
        );
    }, []);

    function filtrarCliente(e) {
        let pesquisa = e.target.value;
        setClientesFiltro(clientes.filter((cli) => {
            return cli.emp_razaosocial.toLowerCase().search(pesquisa.toLowerCase()) !== -1 || cli.emp_nomefantasia.toLowerCase().search(pesquisa.toLowerCase()) !== -1
        }));
    };

    function abrirModal(cliente) {
        setClienteSelecionado(cliente);
        setOpenModalModalDownloadXml(true);
    };

    return (
        <div className="p-10">
            <Row justify="center">
                <Col xxl={18} xl={21} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[0, 16]}>
                        <Col span={24}>
                            <Form layout="vertical">
                                <Form.Item label="Pesquisar por">
                                    <Input.Search placeholder="Pesquisar cliente por Razão Social, Nome Fantasia..." showSearch onChange={filtrarCliente} />
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                {clientesFiltro.map((cliente) => (
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <Card hoverable className="col-empresas">
                                            <Row align="middle" justify="space-between" gutter={[0, 8]}>
                                                <Col span={16}>
                                                    <Row className="m-t-8 p-l-10">
                                                        <Col span={24}>
                                                            <b className="ln-normal f-14 color-primary">
                                                                {cliente.emp_razaosocial}
                                                            </b>
                                                        </Col>
                                                        {(!!cliente.emp_ultimoperiodonfini && !!cliente.emp_ultimoperiodonffim) ? <Col span={24} className="m-t-8">
                                                            Último Período Exportado: <b>{moment(cliente.emp_ultimoperiodonfini).format('DD/MM/YYYY HH:mm:ss')}</b> à <b>{moment(cliente.emp_ultimoperiodonffim).format('DD/MM/YYYY HH:mm:ss')}</b>
                                                        </Col> :
                                                            <Col span={24} className="m-t-8">
                                                                Último Período Exportado: <b>Não localizado</b>
                                                            </Col>}
                                                        <Col span={24}>
                                                            Último Acesso do Cliente: <b>{!!cliente.ultimo_acesso ? moment(cliente.ultimo_acesso).format('DD/MM/YYYY HH:mm:ss') : 'Não localizado'}</b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={8} className="col-logo-emp">
                                                    <img src={`${linkApi}Empresa/RetornaLogo?id=${cliente.emp_id}`} alt="Gestor Web para Contador" />
                                                </Col>
                                                <Col span={24}>
                                                    <Button type="primary" icon={<DownloadOutlined />} block size="large" onClick={() => abrirModal(cliente)}>
                                                        Download de XMLs das NF-e / NFC-e
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalDownloadXml exibirModal={openModalModalDownloadXml} fecharModal={setOpenModalModalDownloadXml} dadosCliente={clienteSelecionado} />
        </div>
    );
}