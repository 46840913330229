import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Modal, Card, notification, Button, Select } from 'antd';
import { DownloadOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";

import { Data } from "../../../components";
import { getToken, linkApi } from '../../../services/auth';
import api from '../../../services/api';
import maskFormat from '../../../services/funcoes';

export default function ModalDownloadXml({ exibirModal, fecharModal, dadosCliente }) {

    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [listaFiliais, setListaFiliais] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
                form.resetFields();
            }
        });
    };

    useEffect(() => {
        if (!!dadosCliente && !!dadosCliente.emp_id) {
            api.get(`Local/ListarFiliaisEmpresa?codigoEmpresa=${dadosCliente.emp_id}&filtro=&apenasAtivas=true`).then(
                res => {
                    setListaFiliais(res.data.items);
                });
        }
    }, [dadosCliente]);

    function realizarDownload(values) {
        setCarregando(true);
        let req = {
            emp_id: dadosCliente.emp_id,
            dataInicial: values.data_inicial,
            dataFinal: values.data_final,
            locais: values.filiais
        }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }

        fetch(`${linkApi}Contador/DownloadLoteXmlNfe`, { headers, ...{ method: 'POST', body: JSON.stringify(req) } })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.blob();
            })
            .then(
                res => {
                    let filename = "ArquivosXML-" + dadosCliente.emp_razaosocial.replaceAll(" ", "_") + ".zip";
                    let objectUrl = URL.createObjectURL(res);
                    let link = document.createElement('a');
                    link.href = objectUrl;
                    link.download = filename;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                    notification.success({ description: "Download realizado com sucesso!", message: 'Aviso!' });
                }
            ).catch(
                erro => {
                    let msg = !!erro && !!erro.message ? erro.message : "Não foi possível realizar o download!";
                    notification.warning({ description: msg, message: 'Aviso!' });
                }
            ).finally(() => {
                fecharModal();
                setCarregando(false);
            });
    };

    return (
        <Modal centered
            title="Download de XML das NF-e/NFC-e"
            visible={exibirModal}
            onCancel={onCloseModal}
            destroyOnClose
            maskClosable={false}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onCloseModal} icon={<CloseOutlined />}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button loading={carregando} icon={<DownloadOutlined />} type="primary" htmlType="submit" onClick={() => form.submit()}>
                            Download
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <Card className="col-empresas">
                        <Row align="middle" justify="space-between" gutter={[0, 0]}>
                            <Col span={16}>
                                <Row className="p-l-10">
                                    <Col span={24}>
                                        <b className="ln-normal f-14 color-primary">
                                            {!!dadosCliente && !!dadosCliente.emp_razaosocial ? dadosCliente.emp_razaosocial : 'Não informado'}
                                        </b>
                                    </Col>
                                    {(!!dadosCliente && !!dadosCliente.emp_ultimoperiodonfini && !!dadosCliente.emp_ultimoperiodonffim) ?
                                        <Col span={24} className="m-t-8">
                                            Último Período Exportado: <b>{moment(dadosCliente.emp_ultimoperiodonfini).format('DD/MM/YYYY HH:mm:ss')}</b> à <b>{moment(dadosCliente.emp_ultimoperiodonffim).format('DD/MM/YYYY HH:mm:ss')}</b>
                                        </Col> :
                                        <Col span={24} className="m-t-8">
                                            Último Período Exportado: <b>Não localizado</b>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                            <Col span={8} className="col-logo-emp">
                                <img src={`${linkApi}Empresa/RetornaLogo?id=${dadosCliente.emp_id}`} alt="Gestor Web para Contador" />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Form layout="vertical" form={form} name="formModal" onFinish={realizarDownload}>
                        <Row gutter={[8, 0]}>
                            <Col>
                                Para exportar o XML de Notas Emitidas, informe abaixo uma data inicial e final.
                            </Col>
                        </Row>
                        <Row gutter={[8, 0]} className="m-t-8">
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                <Data label="Data Inicial" name="data_inicial" initialValue={moment(new Date(), 'DD/MM/YYYY')} rules={[{ required: true }]} />
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                                <Data label="Data Final" name="data_final" rules={[{ required: true }]} />
                            </Col>
                        </Row>
                        <Row gutter={[8, 0]}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Form.Item label="Filiais" name="filiais">
                                    <Select placeholder="Selecione as filiais" mode="multiple" allowClear>
                                        {listaFiliais.map((filial) => (
                                            <Select.Option value={filial.loc_id} key={filial.loc_id}>
                                                {filial.loc_descricao}
                                                {(!!filial.loc_cnpj ? <div>{maskFormat(filial.loc_cnpj.numero, '', true)}</div> : '')}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
}